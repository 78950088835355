import nexus from "../services/nexus";

async function login({ email, senha }) {
  try {
    const { data } = await nexus.post("/login", {
      email,
      password: senha,
      notificationToken: "",
    });
    return {
      status: true,
      token: data.token,
      user: data.user,
    };
  } catch (e) {
    console.log("Erro no login");
    console.log(e);
    return {
      status: false,
      token: "",
      error: "Error",
    };
  }
}

export default {
  login,
};
